import React from "react";
import {
  Container,
  Grid,
  Typography,
  Link,
  Divider,
  Button,
  Hidden,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("Footer");
  const footerLinks = t("footerLinks", { returnObjects: true });
  const btnInfo = t("button", { returnObjects: true });

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Container component="footer" className={styles.root}>
      <Hidden smUp>
        <Grid container className={styles.m_gridbtncontainer}>
          <Grid item xs={12} sm={12} md={12} className={styles.m_gridbtn}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNavigate("/demo")}
              className={styles.m_btn}
            >
              {btnInfo.demo}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNavigate("/contact-us")}
              className={styles.m_btn}
            >
              {btnInfo.contact}
            </Button>
          </Grid>
        </Grid>
        <Divider className={styles.m_divider} />
      </Hidden>
      <Grid container className={styles.gridcontainer}>
        {footerLinks &&
          Array.isArray(footerLinks) &&
          footerLinks.map((Info, index) => (
            <React.Fragment key={index}>
              {/* {index !== 0 && <Divider orientation='vertical' flexItem />}
               */}
              <Grid item xs={12} sm={3} md={3} className={styles.gridinfo}>
                <Typography className={styles.title}>{Info.label}</Typography>
                <Link
                  href={Info.content.link}
                  color="inherit"
                  className={styles.link}
                >
                  <Typography className={styles.content}>
                    {Info.content.text}
                  </Typography>
                </Link>
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12} sm={3} md={3} className={styles.gridinfo}>
          <Grid container className={styles.gridbtncontainer}>
            <Grid item xs={12} sm={12} md={12} className={styles.gridbtn}>
              <Button
                variant="contained"
                onClick={() => handleNavigate("/demo")}
                className={styles.btn}
              >
                {btnInfo.demo}
              </Button>
              <Button
                variant="contained"
                onClick={() => handleNavigate("/contact-us")}
                className={styles.btn}
              >
                {btnInfo.contact}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={styles.divider} />
      <Typography className={styles.copyright}>
        {"© 2022-2025 4SAI Technologies. All rights reserved."}
      </Typography>
    </Container>
  );
};

export default Footer;
